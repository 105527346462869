import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
import defaultSettings from '@/settings'
import Layout from '@/layout'
import { _import } from '@/utils/lazyLoading' //获取组件的方法

/**
 * 重写路由的push方法
 */
 const routerPush = Router.prototype.push
 Router.prototype.push = function push(location) {
   return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter)
const staticRoutes = [
    {
        path:'/cockpit',
        name:'Cockpit',
        component: _import( '/cockpit'),
        meta: {
            title: '呼叫中心驾驶舱'
          }
    },
    {
        path:'/showPlaceAnOrder/:phone/:type/:id?',
        name:'placeAnOrder',
        component: _import( '/placeAnOrder'),
        meta: {
            title: '下单'
          }
    },
    {
        path:'/printa',
        name:'printa',
        component: _import( '/orderManagement/components/printa'),
        meta: {
            title: '打印'
          }
    },
    {
        path:'/printb',
        name:'printb',
        component: _import( '/orderManagement/components/printb'),
        meta: {
            title: '打印'
          }
    },
    {
        path:'/printc',
        name:'printc',
        component: _import( '/orderManagement/components/printc'),
        meta: {
            title: '打印'
          }
    },
    {
        path:'/printd',
        name:'printd',
        component: _import( '/orderManagement/components/printd'),
        meta: {
            title: '打印',//扬州信达
          }
    },
    {
        path:'/printe',
        name:'printe',
        component: _import( '/orderManagement/components/printe'),
        meta: {
            title: '打印',//溧阳
          }
    },
    {
        path:'/printf',
        name:'printf',
        component: _import( '/orderManagement/components/printf'),
        meta: {
            title: '打印',//海安绿洁
          }
    },
    {
        path:'/printg',
        name:'printg',
        component: _import( '/orderManagement/components/printg'),
        meta: {
            title: '打印',//江山崇安燃气
          }
    },
    {
        path:'/printh',
        name:'printh',
        component: _import( '/orderManagement/components/printh'),
        meta: {
            title: '打印',//茂名顺华燃气
          }
    },
    {
        path:'/printi',
        name:'printi',
        component: _import( '/orderManagement/components/printi'),
        meta: {
            title: '打印',//苏州泰华燃气
          }
    },
    {
        path:'/transferOrderPrinting',
        name:'transferOrderPrinting',
        component: _import( '/allocateAndTransfer/components/TransferOrderPrinting'),
        meta: {
            title: '调拨单打印'
          }
    },
    {
        path:'/productInvoicePrinting',
        name:'productInvoicePrinting',
        component: _import( '/productInvoice/components/Print'),
        meta: {
            title: '商品开票打印'
          }
    },
    {
        path: '/',
        name: 'Index',
        hidden: true,
        redirect: '/login',
        meta: {
            title: '首页'
          }
    },
    {
        path: '/login',
        name: 'Login',
        component: _import('/login'),
        hidden: true,
        meta: {
          title: '登录'
        }
    },
    {
        path: '/superAccount',
        name: 'SuperAccount',
        component: _import('/superAccount'),
        hidden: true,
        meta: {
          title: '超级账户'
        }
    },
    {
        path: '*',
        component: _import('/error/404'),
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: _import('/dashboard'),
        hidden: true,
        meta: {
          title: '驾驶舱'
        }
    },
    //押瓶票据打印
    {
        path:'/printRetreat/:type/:id',
        name:'printRetreat',
        component: _import( '/retreatPressureGasCylinder/components/printRetreat'),
        meta: {
            title: '押瓶票据打印',
          }
    },
    // 报修单打印
    {
        path:'/repairRequestPrint/:id',
        name:'repairRequestPrint',
        component: _import( '/repairRequest/components/print'),
        meta: {
            title: '报修单打印'
          }
    },
]

const router = new VueRouter({
    mode: 'hash',
    routes: staticRoutes,
    base: process.env.BASE_URL,
})

//动态路由组件导入
function dynamicRoutes(menus = []){
    const routes = {
        path: '/',
        component: Layout,
        children: [],
        meta: {},
        
    }
    menus.forEach(m => {
        if(m.Url){
            try {
                const route = {
                    name: m.Url.substring(1),
                    path: m.Path || m.Url,
                    component: _import(m.Url),
                    meta: {
                        title: m.Name,
                        icon:m.Icon,
                        visible: m.Visible,
                        buttons: m.Buttons.map(item => {
                            if(item.IsChecked) return item.Name
                        })
                    }
                }
                routes.children.push(route)
            } catch (error) {
                Message.error(`导入组件${m.Url}.vue失败`)
            }
        }       
      })
      //console.log('routes:',routes);
    routes.children.push({
        path: '*',
        component: _import('/error/404'),
    })
    
      return [routes] 
}

// 添加动态路由
export function addRoutes(menus = []) {
    if (menus && menus.length > 0) {
      const dynamicRoutesList = dynamicRoutes(menus)
      //console.log(dynamicRoutesList)
      router.options.routes = staticRoutes.concat([...dynamicRoutesList])//显示静态和动态路由
      //console.log(router)
      router.addRoutes(dynamicRoutesList)
    }
}

// 获取页面标题
function getPageTitle(pageTitle) {
    return `${defaultSettings.title} - ${pageTitle}`
}

// 登录
function toLogin(to, next) {
    console.log('to',to)
    if (to.path === '/login' || to.path == '/superAccount') { 
        next()
      } else {
        next(`/login?redirect=${to.path}`)
    }
}

// 登出
export function toLogout() {
    store.dispatch('user/logout')
    router.push({ name: 'Login' ,params:{
        isFresh:true
    }})
}

// 路由全局前置守卫
router.beforeEach(async (to, from, next) => {
    document.title = getPageTitle(to.meta.title)
    const token = getToken('token')
    if(token){
        if (to.path === '/login' || to.path === '/superAccount' ) {
            next({ path: localStorage.getItem('fp') })
            //toLogin(to, next)
          } else if( to.path ==='/dashboard'){
                let userBelongToType = localStorage.getItem('userBelongType')
                if(userBelongToType ==1){ 
                    next()
                }else{
                    toLogin(to, next)
                }
          } else {
            if (store.getters.menus?.length > 0) {
                next()
            } else {
                let res = await store.dispatch('user/getLoginInfo')
                    if (res) {
                        next({ ...to, replace: true })
                    } else {
                        toLogin(to, next)
                    }        
            }
        }
    }else{
        toLogin(to, next)
    }
})

export default router
