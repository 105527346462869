/*
 * @version:
 * @Author: Lukxwing
 * @Email: stormseeker@163.com
 * @Date: 2022-11-23 17:11:37
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2025-01-14 13:15:24
 */
import request from '@/utils/request';

// 新增
export const CouponAdd = (params, config = {}) => {
  return request({
    url: '/api/Coupon/Add',
    method: 'post',
    data: params,
  });
};

// 详情
export const CouponDetail = (params, config = {}) => {
  return request.get('/api/Coupon/Detail', { params: params, ...config });
};

// 更新
export const CouponUpdate = (params, config = {}) => {
  return request({
    url: '/api/Coupon/Update',
    method: 'put',
    data: params,
  });
};

// 列表
export const CouponList = (params, config = {}) => {
  return request.get('/api/Coupon/List', { params: params, ...config });
};

// 删除
export const CouponDelete = (params, config = {}) => {
  return request.delete('/api/Coupon/Delete', { params: params, ...config });
};

// 客户领取优惠券分页
export const getCouponPageList = (params, config = {}) => {
  return request.get('/api/Coupon/CustomerCouponPageList', { params: params, ...config });
};

// 作废发放的优惠券
export const cancleCustomerCoupon = (params, config = {}) => {
  return request.post('/api/Coupon/CancleCustomerCoupon', params, config);
};

// 删除发放的优惠券
export const deleteCustomerCoupon = (params, config = {}) => {
  return request.post('/api/Coupon/DeleteCustomerCoupon', params, config);
};

// 是否开启开户送券
export const putUpdateCreateGiftConfig = (params, config = {}) => {
  return request.put('/api/Coupon/UpdateCreateGiftConfig', params, config);
};
// 查询列表
export const getCreateGiftCoupon = (params, config = {}) => {
  return request.get('/api/Coupon/GetCreateGiftCoupon', { params: params, ...config });
};
// 删除开户赠送优惠券
export const putDeleteCreateGiftCoupon = (params, config = {}) => {
  return request.put('/api/Coupon/DeleteCreateGiftCoupon', params, config);
};
// 新增修改开户赠送优惠券
export const putAddOrUpdateCreateGiftCoupon = (params, config = {}) => {
  return request.put('/api/Coupon/AddOrUpdateCreateGiftCoupon', params, config);
};
// 获取用户可用的优惠券
export const getCusCouponCanUsable = (params, config = {}) => {
  return request.get('/api/Coupon/GetCusCanUsable', { params: params, ...config });
};
