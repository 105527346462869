import { EventSourcePolyfill } from 'event-source-polyfill';

export function createSSEService(token) {
  let eventSource = null;
  let heartbeatTimeout = null;

  function connect(url) {
    eventSource = new EventSourcePolyfill(url, {
      headers: {
        'authorization': 'Bearer ' + token
      }
    });

    eventSource.addEventListener("open", function(e) {
      console.log('open successfully');
      resetHeartbeatTimeout(url);
    });

    eventSource.addEventListener("message", function(e) {
      console.log(e.data);
      if (e.data === 'heartbeat') {
        resetHeartbeatTimeout(url);
      } else if (e.data === '新订单') {
        let utterance = new SpeechSynthesisUtterance('您有新的微信订单需要处理');
        window.speechSynthesis.speak(utterance);
      } else if (e.data === '新开户') {
        let utterance = new SpeechSynthesisUtterance('您有新的微信开户需要处理');
        window.speechSynthesis.speak(utterance);
      }
    });

    eventSource.addEventListener("error", function(err) {
      console.log(err);
      if (err && err.status === 401) {
        console.log('not authorized');
      }
      close();
      reconnect(url);
    });
  }

  function resetHeartbeatTimeout(url) {
    clearTimeout(heartbeatTimeout);
    heartbeatTimeout = setTimeout(() => {
      console.log('No heartbeat received, reconnecting...');
      close();
      reconnect(url);
    }, 45000); // 45秒内没有收到心跳则重新连接
  }

  function close() {
    if (eventSource) {
      eventSource.close();
    }
    clearTimeout(heartbeatTimeout);
  }

  function reconnect(url) {
    console.log('Reconnecting...');
    connect(url);
  }

  return {
    connect,
    close
  };
}