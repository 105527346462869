/*
 * @Description: 
 * @Author: MasterGeHong
 * @Date: 2022-06-13 13:45:03
 * @LastEditTime: 2025-01-02 15:58:08
 * @LastEditors: wangjieqiong
 */
// 商品管理

import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Product/`

// 列表-商品
export const getProductList = (params, config = {}) => {
    return request.get(apiPrefix + 'List',   { params: params, ...config })
}
//  下单 -商品列表
export const getProductCustomerList = (params, config = {}) => {
    return request.get(apiPrefix + 'CustomerList',   { params: params, ...config })
}
//  调拨所有商品 
export const getProductAllList = (params, config = {}) => {
    return request.get(apiPrefix + 'AllList',   { params: params, ...config })
}


// 新增-商品
export const addProduct = (params, config = {}) => {
    return request.post(apiPrefix + 'Add', params, config)
}


// 修改-商品
export const editProduct = (params, config = {}) => {
    return request.put(apiPrefix + 'Update', params, config)
}
// 单条详情
export const detailProduct = (params, config = {}) => {
    return request.get(apiPrefix + 'SingleDetail', { params: params, ...config })
}
// 删除-商品
export const deleteProduct = (params, config = {}) => { 
    return request.delete(apiPrefix + 'Delete', { params: params, ...config })
} 
// 商品-分类 
export const  getProductClassifyList = (params, config = {}) => { 
    return request.get(apiPrefix + 'ProductClassifyList', { params: params, ...config })
} 
// 商品-分类 -单条详情
export const  getProductClassifySingleDetail = (params, config = {}) => { 
    return request.get(apiPrefix + 'ProductClassifySingleDetail', { params: params, ...config })
} 
// 商品-分类 -修改
export const  putProductClassifyUpdate = (params, config = {}) => { 
    return request.put(apiPrefix + 'ProductClassifyUpdate', params, config)
} 

// 商品-分类 -删除
export const  delProductClassifyDelete = (params, config = {}) => { 
    return request.delete(apiPrefix + 'ProductClassifyDelete', { params: params, ...config })
} 
// 商品-分类 -新增
export const  postProductClassifyAdd = (params, config = {}) => { 
    return request.post(apiPrefix + 'ProductClassifyAdd',  params, config )
} 

// 定时改价

// 添加商品下期价格设置
export const  postProductNextPriceAdd = (params, config = {}) => { 
    return request.post(apiPrefix + 'ProductNextPriceAdd',  params, config )
} 
// 删除商品下期价格设置
export const  deleteProductNextPriceDelete = (params, config = {}) => { 
    return request.delete(apiPrefix + 'ProductNextPriceDelete', { params: params, ...config })
} 
// 修改商品下期价格设置
export const  putProductNextPriceUpdate = (params, config = {}) => { 
    return request.put(apiPrefix + 'ProductNextPriceUpdate',params, config )
} 
// 查询id详情 商品下期价格设置
export const  getProductNextPriceSingleDetail= (params, config = {}) => { 
    return request.get(apiPrefix + 'ProductNextPriceSingleDetail',{ params: params, ...config })
} 
// 查询列表商品下期价格设置
export const  getProductNextPriceList= (params, config = {}) => { 
    return request.get(apiPrefix + 'ProductNextPriceList',{ params: params, ...config })
} 
// 变更日志
export const  getProductNextPriceLogList= (params, config = {}) => { 
    return request.get(apiPrefix + 'ProductNextPriceLogList',{ params: params, ...config })
} 