import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/User/`

// 用户列表
export const getUserList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config })
}
// 新增用户
export const addUser = (params, config = {}) => {
    return request.post(apiPrefix + 'Add', params, config)
}
// 用户修改
export const editUser = (params, config = {}) => {
    return request.put(apiPrefix + 'Update', params, config)
}
// 用户详情
export const detailUser = (params, config = {}) => {
    return request.get(apiPrefix + 'SingleDetail', { params: params, ...config })
}
// 用户删除
export const deleteUser = (params, config = {}) => { 
    return request.delete(apiPrefix + 'Delete', { params: params, ...config })
} 
// 修改密码
export const updatePwd = (params, config = {}) => { 
    return request.put(apiPrefix + 'UpdatePwd', params, config)
} 
// 绑定wx
export const postWxBind = (params, config = {}) => { 
    return request.post(apiPrefix + 'WxBind', params, config)
} 
// 解绑wx
export const postWxUnBind = (params, config = {}) => { 
    return request.post(apiPrefix + 'WxUnBind', params, config)
}
// 打卡记录
export const getSignInList = (params, config = {}) => { 
    return request.get(apiPrefix + 'SignInList', { params: params, ...config })
} 