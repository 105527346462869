<!--
@description: 通用下拉框组件
@prop  {url<String>}       接口URL
@prop  {urlParams<Object>} 接口URL的参数
@prop  {placeholder<string>}  placeholder
@prop  {label<string>}    label字段
@prop  {value<string>}    value字段
-->
<template>
  <el-select
    v-loading="setOperatorId"
    v-model="values"
    :multiple="multiple"
    :value-key="value"
    :placeholder="placeholder"
    @change="handleChange"
    filterable
    clearable
    :disabled="disabled"
    style="width:100%"
  >
    <el-option v-for="item in options" :key="item[value]" :label="item[lable]" :value="item"> </el-option>
  </el-select>
</template>

<script>
import common from '@/api/common';
export default {
  name: 'XkDropDown',
  data() {
    return {
      values: '',
      options: [],
      setOperatorId: false,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    multiple: {
      type: Boolean,
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    lable: {
      type: String,
      default: 'Name',
    },
    value: {
      type: String,
      default: 'Id',
    },
    url: {
      type: String,
    },
    val: {
      type: String | Number | Array,
    },
    paramsWatch: {
      type: String | Number | Object,
    },
    getDataBoolen: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  watch: {
    val: {
      handler(nVal) {
        this.values = this.options.find((user) => {
          return user[this.value] == nVal;
        });
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler(nVal) {
        this.values = this.options.find((user) => {
          return user[this.value] == this.val;
        });
      },
      deep: true,
      immediate: true,
    },
    paramsWatch: {
      handler(newVal, oldVal) {
        this.$emit('update:val', '');
        this.$emit('handleChange', '');
        this.getOptions();
      },
      deep: true,
    },
    urlParams: {
      handler(n, o) {
        this.values = '';
        this.$emit('handleChange', {});
        this.setOperatorId = true;
        setTimeout(() => {
          this.setOperatorId = false;
        }, 500);
        this.getOptions();
      },
    },
  },
  created() {
    this.getOptions();
  },
  methods: {
    async getOptions() {
      if (!this.getDataBoolen) return;
      let params = {};
      //查询条件
      for (let i in this.urlParams) {
        if (this.urlParams[i]) {
          params[i] = this.urlParams[i];
        }
      }
      try {
        let res = await common[this.url](params);
        if (res.code == 1) {
          this.options = res.Data;
        }
      } catch (error) {}
    },
    handleChange(value) {
      this.$emit('handleChange', value);
    },
  },
};
</script>

<style></style>
