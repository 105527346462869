<template>
  <el-container class="layout">
    <el-aside width="270px">
      <div class="logo" @click="jumPage">
        <!-- 呼叫中心系统 -->

        <img class="up-img" v-resize="resize" ref="upimg" :src="'./img/' + srcImage" alt="" />
        <img class="dowm-img" ref="downimg" :src="'./img/logo.png'" alt="" />
      </div>
      <div class="overflow scrollbar flex1">
        <el-menu router :unique-opened="false" :default-openeds="defaultOpeneds" :default-active="$route.path">
          <Menu v-for="menu in menuTree" :key="menu.Id" :item="menu" :menuDataTotal="menuDataTotal"></Menu>
        </el-menu>
      </div>
    </el-aside>
    <el-container class="layout-tabs">
      <el-header height="68px">
        <Header @handBtn="getHandBtn" @changeMenu="cancelGetPageMenuList" :orderShowphone="orderShowphone"></Header>
      </el-header>
      <el-main>
        <div class="breadcrumb">
          <!-- <span class="bread-wrap">
            <i class="el-icon-location-outline"></i>
            <span class="bread-title">首页</span>
            <i class="el-icon-arrow-right"></i>
            <span class="bread-title">{{ getRouteName }}</span>
          </span> -->
          <div class="tags-setting">
            <TagsView />
          </div>
          
          <!-- 自定义表头 -->
          <span class="table-seting">
            <CustomTableSearch v-if="setThList"></CustomTableSearch>
          </span>
        </div>
        
        <keep-alive :include="keepAliveNameList" :max="10">
          <router-view class="layout-content" v-if="isRouterAlive"></router-view>
        </keep-alive>
        <p class="copyright">
          <span class="copyright-left"> Copyright © 2024 <a target="_blank" href="http://www.czxkdz.com/">江苏祥康科技有限公司</a> All rights reserved. </span>
          <span class="copyright-right">Version 3.3.0</span>
        </p>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import CustomTableSearch from '@/compPages/CustomTableSearch';
import placeAnOrder from '@/views/placeAnOrder';
import Header from './components/Header';
import Menu from './components/Menu';
import { listToTree } from '@/utils';
import { mapGetters } from 'vuex';
import { getHeartBeat } from '@/api/auth';
import { getOrderCount } from '@/api/dashboard';
import TagsView from '@/components/TagsView';
import { createSSEService } from '@/services/sseService'
import { getConfigByKey } from '@/api/sysConfig';
export default {
  name: 'Layout',
  data() {
    return {
      routeThList: ['recycledBottle', 'gasUser', 'orderManagement', 'allocateAndTransfer', 'refundReview', 'couponsReport'],
      srcImage: 'logo.png', //配置的左侧logo
      activeIndex: '',
      openeds: [],
      menuTree: [],
      sideMenuTree: [],
      getRouteName: '',
      heartBeatTimerInterval: null, //设置心跳定时器 30,秒请求
      websock: null,
      orderShow: false, // 呼叫下单弹窗
      orderShowphone: '', //呼叫电话
      inPhone: [],
      getCustomerType: '', //手动下单 弹窗页面不关闭
      webScoketCount: 0,
      noWebScoketCount: 0, //失败重连次数
      webScokTime: null, //心跳定时器
      noWebScokTime: null, //监测心跳定时器
      resWebScokTime: null, //失败重连
      resTime: 60 * 3 * 1000, //60秒 *3分钟 *1000毫秒
      menuDataTotal: {},
      isCanceled: true,
      isRouterAlive: true,
      navList: [],
      clearRouterCache: false,
      clearCachePath: '',
      defaultOpeneds:['rrQA7rXW65cgGBHir0GkPQ==','rt0AqL/JzRr5rP3sM0GMug=='],
      sseService:null,
    };
  },
  components: {
    Header, //顶部
    Menu, //左侧菜单
    placeAnOrder, //下单页面弹窗
    CustomTableSearch,
    TagsView,
  },
  beforeDestroy() {
    //页面销毁时关闭ws连接
    if (this.websock) {
      this.websock.close(); // 关闭websocket
    }
    if(this.sseService){
      this.sseService.close();
    }
  },

  computed: {
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
    ...mapGetters(['menus', 'pageButtons', 'visitedRoutes']),
    // 处理详细页面的路由对应关系（勿动！）
    defaultActive: function() {
      console.log('layout', this.$route);
      // if(this.$route.meta.hidden){
      //   return '/'+this.$route.path.split('/')[1];
      // }
      return this.$route.path;
    },
    setThList: function() {
      return this.routeThList.indexOf(this.$route.name) != -1;
    },
    keepAliveNameList() {
      console.log('keepAliveNameList',this.visitedRoutes);

      let list = this.visitedRoutes.map((v) => v.path.split('/')[1]);
      if (this.clearRouterCache) {
        list = list.filter((item) => item != this.clearCachePath.split('/')[1]);
      }
      return list;
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        this.getRouteName = this.$route.meta.title;
        this.$store.commit('user/setPageButons', this.$route.meta.buttons);
      },
      deep: true,
      immediate: true,
    },
    orderShow: {
      handler(n, o) {
        if (!n) {
          this.inPhone.shift();
        }
      },
    },
  },
  created() {
    this.getPageMenuList(); //菜单侧边数据
    // setAccountNumber  登录信息，判断是否是话务员
    let userInfo = JSON.parse(window.localStorage.getItem('setAccountNumber'));
    if (userInfo.RoleId == 'rlsBEWk0in26+X7bjUqrqA==') {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持WebSocket');
        return;
      }
      this.initWebSocket();
    }
    getConfigByKey({ key: 'PushMsg' }).then(res => {
      if(res?.Data?.Value == '1' && this.hasSpecificPaths()){
          this.initWebSSE()
      }
    })
    
  },
  mounted() {
    // 跨页面监听变化
    window.addEventListener('storage', (e) => {
      if (e.key == 'GETHTTPOBJ') {
        this.$router.push({ path: '/orderManagement' });
      }
      if (e.key == 'setMenusData') {
        this.$store.commit('user/setMenusData', Math.random());
        this.getPageMenuList();
      }
    });
    this.getImgLogo();
    this.readFilerName();
    window.onbeforeunload = function() {
      this.websock.close();
    };
  },
  directives: {
    // 使用局部注册指令的方式
    resize: {
      // 指令的名称
      bind(el, binding) {
        // el为绑定的元素，binding为绑定给指令的对象
        let width = '',
          height = '';
        function isReize() {
          const style = document.defaultView.getComputedStyle(el);
          if (width !== style.width || height !== style.height) {
            binding.value(); // 关键
          }
          width = style.width;
          height = style.height;
        }
        el.__vueSetInterval__ = setInterval(isReize, 300);
      },
      unbind(el) {
        clearInterval(el.__vueSetInterval__);
      },
    },
  },
  methods: {
    cancelGetPageMenuList() {
      // 本次关闭，五分钟后正常播报
      window.speechSynthesis.cancel();
      return;
      // 切换语音播报
      this.isCanceled = !this.isCanceled;
      // 关闭触发
      if (!this.isCanceled) {
        this.$store.state.user.topCssBoolen = false;
        clearTimeout(this.speakTime);
        window.speechSynthesis.cancel();
      }
    },
    // 获取表格数据
    async getPageMenuList() {
      clearInterval(this.speakTime);
      let res = await getOrderCount();
      if (res?.Success) {
        this.menuDataTotal = res.Data;
        this.getMenus();
        let a,
          b,
          c,
          e,
          d = 0;
        a = res.Data.Orders;
        b = res.Data.WeCharOrders;
        c = res.Data.WeChatRefunds;
        d = res.Data.WeChatUsers;
        e = res.Data.Repairs;
        // 开启语音播放并且有一项数据不是0的时候可以播报
        if ((a || b || d || e) && this.isCanceled) {
          let text = '';
          if (a || b) {
            console.log('触发语音播报 ab');
            text += '您有新的订单未处理请及时处理。';
          }
          if (d) {
            console.log('触发语音播报 d');
            text += `您有新的微信审核未处理共${res.Data.WeChatUsers}条,请及时处理`;
          }
          if (e) {
            console.log('触发语音播报 e');
            text += `您有新的报修单未处理共${res.Data.Repairs}条,请及时处理`;
          }
          console.log('语音播报内容：',text);
          let utterance = new SpeechSynthesisUtterance(text);
          console.log('语音播报obj：',utterance);

          // 添加开始事件监听器
          utterance.onstart = function(event) {
              console.log('语音播放开始。');
          };
          // 添加结束事件监听器
          utterance.onend = function(event) {
              console.log('语音播放结束。');
          };
          // 添加错误事件监听器
          utterance.onerror = function(event) {
              console.error('语音播放出错：', event.error);
              // event.error.name 会包含错误的类型，如 'network', 'not-allowed', 'syntax' 等
              // event.error.message 会包含更详细的错误信息
          };
          window.speechSynthesis.speak(utterance);
          console.log('触发语音播报 end');
          this.$store.state.user.topCssBoolen = true;
          setTimeout(() => {
            this.$store.state.user.topCssBoolen = false;
          }, 8000);
        }
        let statTime = 3 * 60 * 1000;
        // let statTime = 30 * 1000;
        this.speakTime = setTimeout(() => {
          this.getPageMenuList();
        }, statTime);
      }
    },
    getImgLogo() {
      let res = localStorage.getItem('softwareName');
      let resData = JSON.parse(res);
      this.srcImage = resData?.logo ? resData.logo : '';
    },
    resize() {
      let imgHeight = this.$refs.upimg.clientHeight;
      if (imgHeight == 0) {
        this.$refs.downimg.style.opacity = '1';
      }
    },
    readFilerName() {
      return;
      // 获取地址栏中第一段域名,判断显示logo图片
      let url = window.location.href.split('//')[1].split('.')[0];
      //let locaUrl =url.split(':')[0];
      const path = require('path');
      // 读取指定文件下文件名
      const files = require.context('../../public/img', true, /\.(png|jpg|)$/);
      files.keys().forEach((key) => {
        const name = path.basename(key);
        console.log(name.split('.')[0], url);
        if (name.split('.')[0] == url) {
          this.srcImage = name;
        }
      });
    },
    jumPage() {
      let userInfo = JSON.parse(window.localStorage.getItem('setAccountNumber'));
      //let role =['企业管理员','管理员','']
      if (userInfo.Role.indexOf('管理员') != -1) {
        this.$router.push({ path: '/cockpit' });
      }
    },
    // 手动下单
    getHandBtn(val) {
      //  清除微信受理缓存的 用户id  及订单id
      window.localStorage.setItem('wxslCusId', '');
      // window.localStorage.setItem('wxslOrdId','');
      if (val) {
        this.orderShowphone = val;
      } else {
        this.orderShowphone = '00000000000';
      }
      window.open('../#/showPlaceAnOrder/' + this.orderShowphone + '/web', '_blank', 'width=1500,height=1000,toolbar=no,scrollbars=no,menubar=no,screenX=240,screenY=100');
    },
    //左侧菜单显示
    isCollapseOn(parms) {
      this.isCollapse = parms;
    },
    //获取菜单
    async getMenus() {
      console.log('layout', this.$route);
      if (this.menus?.length > 0) {
        clearInterval(this.heartBeatTimerInterval);
        await getHeartBeat();
        this.heartBeatTimerInterval = setInterval(async () => {
          let res = await getHeartBeat();
        }, 30000);
        this.menus.forEach((item) => {
          if (item.Menus) {
            item.Menus.forEach((cItem) => {
              if (cItem.Path == '/wxOrder') {
                cItem['menusData'] = this.menuDataTotal.WeCharOrders || 0;
              }
              if (cItem.Path == '/orderManagement') {
                cItem['menusData'] = this.menuDataTotal.Orders || 0;
              }
              if (cItem.Path == '/repairRequest') {
                cItem['menusData'] = this.menuDataTotal.Repairs || 0;
              }
              if (cItem.Path == '/wsAccount') {
                cItem['menusData'] = this.menuDataTotal.WeChatUsers || 0;
              }
              if (cItem.Path == '/refundReview') {
                cItem['menusData'] = this.menuDataTotal.WeChatRefunds || 0;
              }
            });
          }
          if (item.Path == '/wxOrder') {
            item['menusData'] = this.menuDataTotal.WeCharOrders || 0;
          }
          if (item.Path == '/orderManagement') {
            item['menusData'] = this.menuDataTotal.Orders || 0;
          }

          if (item.Path == '/wsAccount') {
            item['menusData'] = this.menuDataTotal.WeChatUsers || 0;
          }

          if (item.Path == '/refundReview') {
            item['menusData'] = this.menuDataTotal.WeChatRefunds || 0;
          }
        });
        this.menuTree = listToTree(this.$cloneDeep(this.menus));
        //this.openeds = ['/userManage'];
        // const cloneMenus = this.menus;
        // const path = this.defaultActive; //this.$route.path;
        // const menu = this.menus.find(m => m.Url === path);
        // console.log("cloneMenus",cloneMenus)
        // console.log("path",path)
        // console.log("menu",menu,this.$route)
        // const cloneMenusNew = cloneMenus.find(m => m.Id === menu.ParentId);
        // this.menuTree = listToTree(cloneMenus);
        // this.openeds = this.menus.filter((l) => l.opened).map((l) => l.id + "");
        // console.log("openeds",this.openeds)
      }
    },
    //初始化weosocket
    initWebSocket() {
      clearTimeout(this.resWebScokTime);
      // 登陆时ws 的链接
      let wsWebScoket = JSON.parse(window.localStorage.getItem('setAccountNumber'));
      const wsuri = wsWebScoket.WebSocketUrl + wsWebScoket.SeatNo; // websocket地址 'ws://ecs17.czxkdz.com:20000/'
      //const wsuri = 'ws://ecs29.czxkdz.com:20002/' + wsWebScoket.SeatNo // websocket地址 'ws://ecs17.czxkdz.com:20000/'
      this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    //连接成功
    websocketonopen(e) {
      if (this.webScoketCount == 0) {
        this.$message({
          message: '呼叫功能连接成功',
          type: 'success',
        });
      }
      this.webScoketCount++;
      console.log('连接成功', this.webScoketCount);
      this.wbTime();
    },
    //接收后端返回的数据
    websocketonmessage(e) {
      this.wbTime();
      let dataJson = JSON.parse(e.data);
      console.log(dataJson.module);
      if (dataJson.module != 'heart') {
        this.orderShowphone = dataJson.phone;
        window.localStorage.setItem('callid', dataJson.callid);
        window.localStorage.setItem(`callid${dataJson.phone}`, dataJson.callid);
        this.getHandBtn(dataJson.phone);
      }
    },
    //连接建立失败重连
    websocketonerror(e) {
      console.log('连接失败.', e);
      // 每重连失败5次 提示1次失败信息
      if (this.noWebScoketCount == 5) {
        this.noWebScoketCount = 0;
        this.$message({
          message: '连接失败! 正在重新连接...',
          type: 'warning',
        });
      }
      this.resWebScokTime = setTimeout(() => {
        this.noWebScoketCount++;
        console.log(`失败重连：`, this.noWebScoketCount);
        this.initWebSocket(); // 连接失败后尝试重新连接
      }, 60000);
    },
    //关闭连接
    websocketclose(e) {
      console.log('断开连接.', e);
      this.initWebSocket();
    },
    orderShowFun() {
      this.orderShow = false;
    },
    // websocket定时器
    wbTime() {
      clearTimeout(this.webScokTime);
      clearTimeout(this.noWebScokTime);
      this.webScokTime = setTimeout(() => {
        this.websock.send('heart');
        this.noWebScokTime = setTimeout(() => {
          this.websock.close();
        }, this.resTime);
      }, 60000);
    },
    initWebSSE(){
      this.sseService = createSSEService(window.sessionStorage.getItem('token'));
      this.sseService.connect('/api/SSE/stream');
    },
    hasSpecificPaths() {
      const targetPaths = ['/wxOrder', '/wsAccount'];
      return this.menus.some(menu => targetPaths.includes(menu.Path));
    },
  },
};
</script>
<style lang="scss" scoped>
.layout {
  height: 100%;
  .logo {
    height: $header-height;
    line-height: $header-height;
    color: #fff;
    font-size: 30px;
    text-align: center;
    overflow: hidden;
  }
  ::v-deep .el-header {
    padding: 0;
    @include nav;
  }
  ::v-deep .el-aside {
    width: 248px !important;
    height: 100%;
    background-color: var(--left-bg);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  ::v-deep .el-menu {
    border-right: 0;
    //background-color: #fff;
  }
  ::v-deep .el-main {
    padding: 0px;
    display: flex;
    flex-direction: column;
  }
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .layout-tabs {
    height: 100%;
    overflow: hidden;
    .page-component__scroll {
      flex: 1;
      overflow-y: auto;
    }
  }
  .breadcrumb {
    background-color: #fff;
    height: 50px;
    line-height: 50px;
    padding-left: var(--space20);
    padding-right: var(--space20);
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    .bread-wrap {
      color: var(--bread-color);
      display: flex;
      align-items: center;
      i.el-icon-arrow-right {
        font-size: 12px;
      }
    }
    .tags-setting{
      flex: 1;
      overflow: hidden;
    }
    .table-seting{
      padding: 0 0 0 15px;
    }
  }
  .layout-content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    height: 0;
    margin: 14px 20px 8px;
  }
}
.bread-title {
  font-size: 14px;
  font-weight: normal;
}
.copyright {
  padding: 0px 0 10px;
  font-size: 14px;
  color: #999;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  // text-align: center;
  .copyright-left {
    margin-left: 20px;
  }
  .copyright-right {
    margin-right: 20px;
  }
}
// 图片显示
.logo {
  position: relative;
}
.up-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.dowm-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
}
</style>
