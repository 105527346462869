/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-10-18 17:16:31
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2025-01-09 09:18:59
 * @FilePath: \src\api\sysConfig.js
 */
// 系统配置
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Config/`

// 列表
export const getConfigList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config })
} 
// 正式隐藏配置-列表
export const getConfigAllList = (params, config = {}) => {
    return request.get(apiPrefix + 'AllList', { params: params, ...config })
} 
// 修改
export const getConfigUpdata = (params, config = {}) => {
    return request.put(apiPrefix + 'Update',  params, config)
} 

// 积分配置

// 获取积分兑换配置项 /api/Config/GetPointsRedemption
export const getGetPointsRedemption = (params, config = {}) => {
    return request.get(apiPrefix + 'GetPointsRedemption',  {params:params, ...config})
} 
// 修改积分兑换配置项 /api/Config/UpdatePointsRedemption 
export const upDatePointsRedemption= (params, config = {}) => {
    return request.put(apiPrefix + 'UpdatePointsRedemption',  params, config)
} 


export const CusPointValue = (params, config = {}) => {
    return request.get("/api/Config/Get?key=CusPointValue", params, config)
}

export const UpdateCusPointValue = (params, config = {}) => {
    console.log(params)
    return request({
        url: "/api/Config/Update",
        method: "put",
        data: params
    })
}

// 更新配送配置
export const UpdateDeliverConfig = (params) => {
    return request({
        url: "/api/Config/UpdateDeliverConfig",
        method: "put",
        data: params
    })
}

// 获取配送配置
export const GetDeliverConfig = (params) => {
    return request({
        url: "/api/Config/GetDeliverConfig",
        method: "get",
        params
    })
}

// 启用/禁用 配送配置
export const EnableDeliverConfig = (params) => {
    return request({
        url: "/api/Config/EnableDeliverConfig",
        method: "get",
        params
    })
}
// 获取配置项
export const getConfig = (params) => {
    return request({
        url: "/api/Config/Get",
        method: "get",
        params
    })
}

// 根据KEY获取配置项
export const getConfigByKey = (params) => {
    return request({
        url: "/api/Config/GetConfigByKey",
        method: "get",
        params
    })
}

// 新的配送费接口-获取配送费
export const GetDeliveryConfig = (params, config = {}) => {
    return request.get('/api/FeesConfig/GetDeliveryConfig',  {params:params, ...config})
}
// 新的配送费接口-修改
export const EditDeliveryConfig = (params, config = {}) => {
    return request.post('/api/FeesConfig/EditDeliveryConfig',  params, config)
}
// 获取燃气用户配送费用
export const GetCusDeliveryPrice= (params, config = {}) => {
    return request.get('/api/CalculateDeliverPrice/GetPrice', {params:params, ...config})
}
