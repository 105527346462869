<!--
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-08-04 12:14:47
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2025-01-15 09:28:22
 * @FilePath: \src\compPages\GasUForm.vue
-->
<template>
  <div>
    <el-form ref="form" :inline="true" class="add-form-box add-form-style" :rules="rules" :label-position="'right'" label-width="100px" :model="upData">
      <el-form-item v-if="urlstring">
        <el-col :span="12">
          <el-form-item label="末次入户安检时间" label-width="135px">
            <el-date-picker style="width:100%" disabled v-model="upData.LastInspectTime" value-format="yyyy-MM-dd" type="date" placeholder="末次入户安检时间"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="末次入户安检结论" label-width="135px">
            {{ upData.InspectionResult || '无' }}
            <span v-if="propsData.Id" style="color:#409EFF;cursor: pointer;padding-left: 10px;" @click="getDetailInfo">入户安检详情</span>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item v-if="urlstring">
        <el-col :span="12">
          <el-form-item label="末次随瓶安检时间" label-width="135px">
            <el-date-picker style="width:100%" disabled v-model="upData.LastInspectTimeWithBottle" value-format="yyyy-MM-dd" type="date" placeholder="末次随瓶安检时间"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="末次随瓶安检结论" label-width="135px">
            {{ upData.LastInspectTimeWithBottleResult || '无' }}
            <span v-if="propsData.Id" style="color:#409EFF;cursor: pointer;padding-left: 10px;" @click="getBotDetailInfo">随瓶安检详情</span>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item v-if="urlstring && safeCheckFlag">
        <el-col :span="12">
          <el-form-item label="安心管摸排情况">
            {{ upData.SafetyCheckResultText || '无' }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="安心管置换状态">
            {{ upData.SubstitutionStatusText || '无' }}
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="12">
          <el-form-item label="客户名称" prop="Name">
            <el-input maxLength="100" width="80%" placeholder="请输入客户名称" clearable v-model="upData.Name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属站点" prop="StationId">
            <DropDownStationList :val.sync="upData.StationId" placeholder="请选择站点"></DropDownStationList>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="12">
          <el-form-item label="联系人">
            <el-input maxLength="100" width="80%" clearable placeholder="请输入联系人" v-model="upData.Contact"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户类型">
            <el-radio-group v-model="upData.TypeId" @change="typeIdChange">
              <el-radio v-for="(item, index) in typeOptions" :key="index" :label="item.Id">{{ item.Name }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-col :span="24">
        <el-form-item label="是否开票客户" v-if="isWmyhq">
          <el-radio-group v-model="upData.ProductInvoice">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="24">
        <el-form-item label="联系电话1" prop="PhoneList">
          <el-col :span="22">
            <el-input maxLength="500" width="80%" clearable placeholder="请输入联系电话" v-model="upData.PhoneList[0]" @blur="triggerValidation('PhoneList')"></el-input>
          </el-col>
          <el-col :span="2">
            <i class="el-icon-circle-plus-outline" @click="changeShowPhone(1)"></i>
          </el-col>
        </el-form-item>
      </el-col>
      <el-col :span="24" v-for="(item, index) in upData.PhoneList.slice(1)" :key="index">
        <el-form-item :label="'联系电话' + (index + 2)">
          <el-col :span="22">
            <el-input maxLength="500" width="80%" clearable placeholder="请输入联系电话" v-model="upData.PhoneList[index + 1]" @blur="triggerValidation('PhoneList')"></el-input>
          </el-col>
          <el-col :span="2">
            <i class="el-icon-remove-outline" @click="changeShowPhone(2, index + 1)"></i>
          </el-col>
        </el-form-item>
      </el-col> -->
      <el-col :span="24">
          <el-form-item
            v-for="(domain, index) in upData.PhoneList"
            :label="'联系电话' + (index+1)"
            :key="index"
            :prop="'PhoneList.' + index"
            :rules="[
              {required: true, message: '请输入联系电话,不小于7位', trigger: 'blur'},
              { pattern: /^\d{7,13}$/, message: '每个号码格式大于7位、小于13位！',trigger:'blur'}
            ]"
          >
            <el-input v-model="upData.PhoneList[index]" style="width:200px;margin-right: 10px;"></el-input><el-button v-if="index==0" @click="addPhone(1)" icon="el-icon-plus" circle type="primary" size="small" plain></el-button><el-button icon="el-icon-minus" circle type="danger" size="small" plain v-else @click="addPhone(2,index)"></el-button>
          </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否黑名单" prop="IsOrder">
          <el-radio-group v-model="upData.IsOrder" disabled>
            <el-radio :label="false">是</el-radio>
            <el-radio :label="true">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="是否允许下单" prop="IsCanOrder">
          <el-radio-group v-model="upData.IsCanOrder">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="默认送气工" prop="">
          <XkDropDown key="setDefaultDelivererId" :url="'getOperatorList'" :val.sync="upData.DefaultDelivererId" placeholder="默认送气工" lable="Name" value="Id"></XkDropDown>
        </el-form-item>
      </el-col>
      <el-form-item label="原因" prop="RefuseOrderReason" v-if="!upData.IsOrder">
        <el-input maxLength="1000" clearable width="80%" placeholder="请输入黑名单原因" v-model="upData.RefuseOrderReason"></el-input>
      </el-form-item>
      <el-col :span="12" v-if="setHostName">
        <el-form-item label="瓶属类型">
          <el-select v-model="upData.CylinderSelf" disabled>
            <el-option label="非自有瓶" :value="false"></el-option>
            <el-option label="自有瓶" :value="true"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <!-- residentId 居民 -->
      <el-col :span="12" v-show="upData.TypeId != residentId">
        <el-form-item label="细分类型" :prop="upData.TypeId != residentId ? 'SubTypeId' : 'a'">
          <XkDropDown url="getCustomerSubTypeList" :val.sync="upData.SubTypeId" lable="Name" value="Id" placeholder="细分类型"></XkDropDown>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-show="upData.TypeId == residentId">
        <el-form-item label="细分类型" :prop="locationItem.resident && upData.TypeId == residentId ? 'ResidentSubType' : 'a'">
          <XkDropDown url="getResidentSubTypeList" :val.sync="upData.ResidentSubType" lable="key" value="value" placeholder="细分类型"></XkDropDown>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="upData.TypeId != residentId">
        <el-form-item label="社会信用代码" :prop="LicenseNumberRequired == '1' && upData.TypeId != residentId ? 'LicenseNumber' : ''">
          <!-- :prop="upData.TypeId == residentId ? '' : 'LicenseNumber'" -->
          <el-input maxLength="100" placeholder="非居民社会信用代码" clearable v-model="upData.LicenseNumber"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="身份证号" :prop="IdentityNoRequired == '1' && upData.TypeId == residentId ? 'IdentityNo' : 'noIdentityNo'">
          <el-input maxLength="100" width="80%" clearable oninput="value=value.replace(/[^0-9xX]/g,'')" placeholder="请输入身份证号" v-model="upData.IdentityNo"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="业务员" prop="SalesMan">
          <XkDropDown url="getSalesmanList" placeholder="请选择业务员姓名" lable="Name" value="Id" :val.sync="upData.SalesmanId"> </XkDropDown>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="创建者">
          <el-input v-model="upData.CreatorName" placeholder="创建者" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-form-item label="月结支付">
        <el-radio-group v-model="upData.IsMonthLimit">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="upData.IsMonthLimit">
        <el-col :span="12">
          <el-form-item label="月结截止日期" prop="MonthLimitTime">
            <el-date-picker style="width:100%" v-model="upData.MonthLimitTime" value-format="yyyy-MM-dd" type="date" placeholder="月结截止日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="月结额度" prop="MonthLimit">
            <!-- <el-input v-model="upData.MonthLimit" type="number" min="1" max="99999999.99" placeholder="请输入月结额度"></el-input> -->
            <el-input-number style="width:100%;" v-model="upData.MonthLimit" controls-position="right" :min="0" :max="99999999.99"></el-input-number>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-col :span="12">
        <el-form-item label="身份证地址">
          <el-input maxLength="100" width="80%" clearable placeholder="请输入身份证地址" v-model="upData.IdentityAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="楼层">
          <el-input v-model.trim="upData.Floors" @input="upData.Floors = upData.Floors > 0 ? $event.replace(/[^0-9]/g, '') : ''" :clearable="true" maxlength="3"></el-input>
        </el-form-item>
      </el-col>
      <el-form-item label="用气地址" prop="GasAddress">
        <el-col :span="showAddress == 'show' ? 21 : 24">
          <el-input maxLength="100" width="80%" placeholder="请输入用气地址" v-model="upData.GasAddress" clearable></el-input>
        </el-col>
        <el-col :span="3" v-if="showAddress == 'show'" style="display: flex;justify-content: flex-end;">
          <el-button @click="showMap" type="primary" circle icon="el-icon-location">  </el-button>
        </el-col>
      </el-form-item>

      <!-- StreetCode 区划的校验 -->
      <el-form-item label="所属区划" :prop="locationItem ? 'StreetCode' : 'no'">
        <DropDownDivision ref="sst" :setLevel="4" :type="type" :areaCode="userStreetCode" @region="getDivision" placeholder="请选择所属省份"></DropDownDivision>
      </el-form-item>
      <el-row>
        <el-col :span="24">
          <el-form-item v-if="sysForm.js == 1" label="金属波纹管" prop="MetalPipe">
            <el-radio-group v-model="upData.MetalPipe">
              <el-radio :label="true">更换</el-radio>
              <el-radio :label="false">未更换</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item v-if="sysForm.jsTime == 1" label="安装时间" prop="MetalPipeInstallTime">
            <el-date-picker v-model="upData.MetalPipeInstallTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择安装时间"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row> 
        
        
        <el-form-item v-if="sysForm.nd == 1" label="浓度报警器" prop="ConcentrationAlarm">
          <el-radio-group v-model="upData.ConcentrationAlarm">
            <el-radio :label="true">安装</el-radio>
            <el-radio :label="false">未安装</el-radio>
          </el-radio-group>
        </el-form-item>
      <el-form-item v-if="sysForm.gl == 1" label="过流切断阀" prop="FlowValve">
        <el-radio-group v-model="upData.FlowValve">
          <el-radio :label="true">使用</el-radio>
          <el-radio :label="false">未使用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="sysForm.Ljxhzz == 1" label="炉具熄火装置" prop="StoveDevice">
        <el-radio-group v-model="upData.StoveDevice">
          <el-radio :label="true">安装</el-radio>
          <el-radio :label="false">未安装</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="sysForm.rsq == 1" label="热水器" prop="StoveDevice">
        <el-radio-group v-model="upData.WaterHeater">
          <el-radio label="1">直排式</el-radio>
          <el-radio label="2">非直排式</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="sysForm.siq == 1" label="灶具">
        <el-radio-group v-model="upData.StoveIsQualified">
          <el-radio :label="true">合格</el-radio>
          <el-radio :label="false">不合格</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="sysForm.rviq == 1" label="调压阀">
        <el-radio-group v-model="upData.RegulatingValveIsQualified">
          <el-radio :label="true">合格</el-radio>
          <el-radio :label="false">不合格</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input maxLength="1000" clearable type="textarea" :rows="1" width="80%" placeholder="请输入备注" v-model="upData.Remark"></el-input>
      </el-form-item>
    </el-form>

    <XkMaxDialog :dialogVisible.sync="showMapBox" title="地图" width="100%" height="100vh" :getIsfull="false" :titleVisible.sync="showMapBox" @closeFun="closeFun">
      <template #content>
        <MapBox :address="StreetName + upData.GasAddress" @getMap="getMap"></MapBox>
      </template>
    </XkMaxDialog>

    <!-- 详情 -->
    <XkMaxDialog :dialogVisible.sync="detailForm.show" :title="detailForm.title" width="1200px" :getIsfull="false" :titleVisible.sync="detailForm.show">
      <template #content>
        <TempDynamic v-if="detailForm.templateName == 'NewTemplete'" :formData="detailForm"></TempDynamic>
        <TempDynamicOld v-else-if="detailForm.templateName == 'OldTemplete'" :formData="detailForm"></TempDynamicOld>
        <BottleTempDynamic v-else-if="detailForm.templateName == 'BottleTempDynamic'" :formData="detailForm"></BottleTempDynamic>
      </template>
    </XkMaxDialog>
  </div>
</template>
<script>
import MapBox from './Map.vue';
import XkDropDown from '@/components/DropDown/XkDropDown';
import DropDownStationList from '@/components/DropDown/StationList';
import DropDownDivision from '@/components/DropDown/Division';
import { getCustomerTypeListAsync } from '@/api/common.js';
import { formReg } from '@/utils/global.js';
import { mapGetters } from 'vuex';
import { getConfigList } from '@/api/sysConfig';
import { getLastInspection } from '@/api/customer';
import { getCustomerLastInspectDetail, getLastInspectionDetail } from '@/api/safetyCheck';
import householdInspection from '@/mixin/householdInspection.js';
export default {
  name: 'GasUForm',
  mixins: [householdInspection],
  data() {
    // 身份证号码验证
    var getIdentityNo = (rule, value, callback) => {
      let reg = formReg['IdentityNo'];
      if (this.upData.TypeId == this.residentId && this.IdentityNoRequired == '1') {
        if (value === '') {
          // 居民身份证必输
          callback(new Error('请输入身份证号'));
        } else {
          if (!reg.test(value)) {
            callback(new Error('身份证格式不正确'));
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };
    return {
      showPhone: 1,
      showMapBox: false, //地图显示
      upData: {
        PhoneList: [''],
        IsCanOrder: true,
        CylinderSelf: false,
        Name: '',
        Number: '',
        Phone: '',
        GasAddress: '',
        AreaCode: '',
        StreetCode: '',
        StationId: '',
        IdentityNo: '',
        TypeId: '',
        SalesMan: '',
        SalesmanId: '',
        RefuseOrderReason: '',
        IsOrder: true,
        IsMonthLimit: false,
        ResidentSubType: '',
        GasAddressList: [],
        WaterHeater: '',
        MetalPipeInstallTime: '',
      },
      sysForm: {
        js: 0,
        jsTime:0,
        nd: 0,
        gl: 0,
        Ljxhzz: 0,
        rsq: 0,
        siq:0,
        rviq:0,
      },
      StreetName: '',
      IdentityNoRequired: '', //判断身份证是否必输 1 是 0 否
      LicenseNumberRequired: '', //判断非居民社会信用代码是否必输
      customerType: [], //用户类型
      rules: {
        Name: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        StationId: [{ required: true, message: '请选择站点', trigger: 'change' }],
        Phone: [
          { required: true, min: 7, message: '请输入联系电话,不小于7位', trigger: 'blur' },
          {
            pattern: /[0-9]$/,
            message: '手机号码格式不正确',
            trigger: 'blur',
          },
        ],
        IdentityNo: [
          { required: true, validator: getIdentityNo, trigger: 'blur' },
          //{ pattern: /^[1-9][0-9]{5}(19[1-9]{1}[0-9]{1}|194[2-9]{1}|200[0-4]{1})((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|30|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/, message: "身份证格式不正确", trigger: "blur", },
        ],
        noIdentityNo: [{ required: false, trigger: 'blur' }],
        SubTypeId: [{ required: true, message: '请选择细分类型', trigger: 'change' }],
        ResidentSubType: [{ required: true, message: '请选择细分类型', trigger: 'change' }],
        IdentityAddress: [{ required: true, message: '请输入身份证地址', trigger: 'blur' }],
        GasAddress: [{ required: true, message: '请输入用气地址', trigger: 'blur' }],
        StreetCode: [{ required: true, message: '请选择所属区划', trigger: 'blur' }],
        LicenseNumber: [{ required: true, message: '请输入非居民社会信用代码', trigger: 'blur' }],
        RefuseOrderReason: [{ required: true, message: '请输入不能下单的原因', trigger: 'blur' }],
        MonthLimit: [{ required: true, message: '请输入月结额度', trigger: 'blur' }],
        MonthLimitTime: [{ required: true, message: '请选择截止日期', trigger: 'blur' }],
        PhoneList: [
          { required: true, message: '请输入联系电话,不小于7位', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value.some((tag) => tag.length < 7) || value.some((tag) => tag.length > 13)) {
                callback(new Error('每个号码格式，大于7位、小于13位！'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
      typeOptions: [],
      residentId: '', //居民的id值
      userStreetCode: [],
      cusInfoInspec: {
        LatestInspectionTime: '',
        InspectionResult: '',
      },
      locationItem: {},
      detailForm: {
        show: false,
        templateName: '',
        templateType: '',
        TemplateId: '',
        Templete: '',
        title: '详情',
        form: {},
      },
      PcShowMetalPipeInstallTime: false,
    };
  },
  watch: {
    propsData: {
      handler(n, o) {
        this.upData.PhoneList[0] = this.propsData?.Phone;
        if (!n.Id) return;
        this.upData = Object.assign({}, this.propsData);
        this.upData.IsMonthLimit = this.propsData.IsMonthLimit ? this.propsData.IsMonthLimit : false;
        this.upData.IsOrder = this.propsData?.IsOrder == undefined ? true : this.propsData?.IsOrder;
        this.upData.IsCanOrder = this.propsData?.IsCanOrder ? true : false;
        this.upData.PhoneList = this.propsData.Phone ? this.propsData.Phone.split('/') : [''];
        let code = this.propsData.StreetCode ? this.propsData.StreetCode : '';

        switch (code?.length) {
          case 0:
            console.log('区划为空字符串');
            break;
          case 2:
            this.userStreetCode = [code?.substring(0, 2)];
            break;
          case 4:
            this.userStreetCode = [code?.substring(0, 2), code];
            break;
          case 6:
            this.userStreetCode = [code?.substring(0, 2), code.substring(0, 4), code];
            break;
          case 9:
            this.userStreetCode = [code?.substring(0, 2), code.substring(0, 4), code.substring(0, 6), code];
            break;
          default:
            this.userStreetCode = [code?.substring(0, 2), code.substring(0, 4), code.substring(0, 6), code.substring(0, 9), code];
        }
        this.$refs.sst.handleChange();
        this.upData.StreetCode = this.propsData.StreetCode;
        this.upData = JSON.parse(JSON.stringify(this.upData));
        //查询用户安检信息
        // if (location.href.indexOf('web') != -1 || location.href.indexOf('wxsl') != -1) {
        //   console.log('安检');
        //   if (this.propsData.Id) this.cusLastInspection(this.propsData.Id);
        // }
      },
      deep: true,
      // immediate: true,
    },
  },
  components: {
    XkDropDown,
    DropDownStationList,
    DropDownDivision,
    MapBox,
  },
  props: {
    showAddress: {
      type: String,
      default: '',
    },
    propsData: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'add',
    },
  },
  computed: {
    ...mapGetters(['hostConfig']),

    urlstring: function() {
      if (location.href.indexOf('web') != -1 || location.href.indexOf('wxsl') != -1) {
        return true;
      }
    },
    setHostName() {
      return location.hostname == 'calllyyhq.bi-tac.cn';
    },
    safeCheckFlag: function() {
      return this.hostConfig?.StationCode.startsWith('3201');
    },
    // 是否绥中大王庙 calldwmyhq.bi-tac.cn/	
    isWmyhq(){
      return window.location.href.split('//')[1].split('.')[0] == 'calldwmyhq'
    }
  },
  created() {
    let GETHTTPOBJDATA = window.localStorage.getItem('GETHTTPOBJ');
    this.locationItem = GETHTTPOBJDATA == 'undefined' ? {} : JSON.parse(GETHTTPOBJDATA);
    // this.upData.Phone = this.propsData.Phone;
    this.getSysConfig();
    this.getCustomerTypeList();
    if (this.type == 'add') {
      this.upData.CreatorName = this.hostConfig.Name;
      this.upData.Creator = this.hostConfig.Id;
      let json = JSON.parse(window.localStorage.getItem('GETHTTPOBJ'));
      let code = json.streetCode ? json.streetCode : '';
      this.upData.StreetCode = code;
      this.upData.AreaCode = code?.substring(0, 6);
      if (!code) return;
      switch (code?.length) {
        case 0:
          console.log('区划为空字符串');
          break;
        case 2:
          this.userStreetCode = [code?.substring(0, 2)];
          break;
        case 4:
          this.userStreetCode = [code?.substring(0, 2), code];
          break;
        case 6:
          this.userStreetCode = [code?.substring(0, 2), code.substring(0, 4), code];
          break;
        case 9:
          this.userStreetCode = [code?.substring(0, 2), code.substring(0, 4), code.substring(0, 6), code];
          break;
        default:
          this.userStreetCode = [code?.substring(0, 2), code.substring(0, 4), code.substring(0, 6), code.substring(0, 9), code];
      }
    } else {
      this.upData = Object.assign({}, this.propsData);
      this.upData.IsMonthLimit = this.propsData.IsMonthLimit ? this.propsData.IsMonthLimit : false;
      this.upData.PhoneList = this.propsData.Phone ? this.propsData.Phone.split('/') : [''];
      // this.upData.StreetCode = this.propsData.StreetCode;
      let code = this.propsData.StreetCode ? this.propsData.StreetCode : '';

      // this.userStreetCode = code && [
      //   code.substring(0, 2),
      //   code.substring(0, 4),
      //   code.substring(0, 6),
      //   code
      // ];
      switch (code?.length) {
        case 0:
          console.log('区划为空字符串');
          break;
        case 2:
          this.userStreetCode = [code?.substring(0, 2)];
          break;
        case 4:
          this.userStreetCode = [code?.substring(0, 2), code];
          break;
        case 6:
          this.userStreetCode = [code?.substring(0, 2), code?.substring(0, 4), code];
          break;
        case 9:
          this.userStreetCode = [code?.substring(0, 2), code?.substring(0, 4), code?.substring(0, 6), code];
          break;
        default:
          this.userStreetCode = [code?.substring(0, 2), code?.substring(0, 4), code?.substring(0, 6), code?.substring(0, 9), code];
      }
    }
    this.upData = JSON.parse(JSON.stringify(this.upData));
  },
  mounted() {},
  methods: {
    triggerValidation(prop) {
      this.$refs.form.validateField(prop);
    },
    changeShowPhone(type, index) {
      let oLength = this.upData.PhoneList.length;
      if (type == 1 && oLength < 3) {
        this.upData.PhoneList.push('');
      }

      if (type == 2 && this.showPhone > 0) {
        this.upData.PhoneList.splice(index, 1);
      }
    },
    addPhone(type, index) {
      let oLength = this.upData.PhoneList.length;
      if (type == 1 && oLength < 3) {
        this.upData.PhoneList.push('');
      }

      if (type == 2 && this.showPhone > 0) {
        this.upData.PhoneList.splice(index, 1);
      }
    },
    // 用户末次安检
    async cusLastInspection(id) {
      try {
        let res = await getLastInspection({ customerid: id });
        if (res?.Success) {
          this.cusInfoInspec.LatestInspectionTime = res.Data?.LatestInspectionTime || '';
          this.cusInfoInspec.InspectionResult = res.Data?.InspectionResult;
          // this.cusInfoInspec.InspectionResult = res.Data?.InspectionResult == 1 ? '合格' : res.Data?.InspectionResult == 0 ? '不合格' : '';
        }
      } catch (error) {
        console.log(error);
      }
    },
    addGasAddressList() {
      this.upData.GasAddressList.push({});
    },
    handleChange(value) {},
    getMap(text) {
      this.upData.GasAddress = text[0].text;
      this.showMapBox = false;
    },
    showMap() {
      this.showMapBox = true;
    },
    closeFun() {
      this.showMapBox = false;
    },
    // 获取配置判断身份证是否必输
    async getSysConfig() {
      let res = await getConfigList();
      let sysConfigFrom = JSON.parse(JSON.stringify(res.Data));
      if (res.Success) {
        sysConfigFrom.forEach((item) => {
          if (item.Key == 'IdentityNoRequired') {
            console.log('IdentityNoRequired', item);
            this.IdentityNoRequired = item.Value;
          }
          if (item.Key == 'LicenseNumberRequired') {
            this.LicenseNumberRequired = item.Value;
          }
          if (item.Key == 'Lqdf') {
            this.sysForm.gl = item.Value;
          }
          if (item.Key == 'Ndbjq') {
            this.sysForm.nd = item.Value;
          }
          if (item.Key == 'Jsbwg') {
            this.sysForm.js = item.Value;
          }
          if (item.Key == 'Ljxhzz') {
            this.sysForm.Ljxhzz = item.Value;
          }
          if (item.Key == 'WaterHeaterShow') {
            this.sysForm.rsq = item.Value;
          }
          if (item.Key == 'PcShowMetalPipeInstallTime') {
            this.sysForm.jsTime = item.Value;
          }
          if (item.Key == 'PcShowStoveIsQualified') {
            this.sysForm.siq = item.Value;
          }
          if (item.Key == 'PcShowRegulatingValveIsQualified') {
            this.sysForm.rviq = item.Value;
          }
        });
      }
    },
    // 客户类型change
    typeIdChange(val) {
      this.$refs.form.validateField('IdentityNo');
      // if (val != this.residentId) {
      //   // 非居民清空身份证
      //   this.upData.IdentityNo = "";
      // }
    },
    // 获取用户类型
    async getCustomerTypeList() {
      let res = await getCustomerTypeListAsync();
      this.typeOptions = res.Data;
      let custom = res.Data.find((item) => item.Name == '居民');
      this.residentId = custom.Id; // 设置居民ID值
      if (this.type == 'add') {
        this.upData.TypeId = custom.Id;
      }
    },
    // 获取区划组件传送的值
    getDivision(val, params) {
      if (val) {
        this.upData.StreetName = val.label;
        this.upData.StreetCode = val.value;
        this.upData.AreaCode = val.value.substring(0, 6);
        if (params?.length == 1) this.StreetName = params[0];
        if (params?.length > 1) this.StreetName = params[0] + params[1];
      } else {
        this.upData.StreetCode = '';
      }
    },
    // 获取表单的值
    getFormData() {
      return this.upData;
    },
    // 获取末次入户安检详情
    async getDetailInfo() {
      try {
        let params = {
          CustomerId: this.propsData.Id,
        };
        let res = await getCustomerLastInspectDetail(params);
        console.log(res);
        if (res?.Success) {
          if (!res.Data.Data) return this.$message.warning('该用户无末次入户安检');
          this.detailForm.TemplateId = res.Data.Data.TemplateId;
          this.detailForm.CusId = this.propsData.Id;
          this.detailForm.Type = this.upData.TypeName == '居民' ? 1 : 2;
          this.detailForm.form = res.Data.Data;
          if (res.Data.Data.TemplateId) {
            this.detailForm.Templete = res.Data.NewTemplete;
            this.detailForm.templateName = 'NewTemplete';
          } else {
            this.detailForm.Templete = res.Data.OldTemplete;
            this.detailForm.templateName = 'OldTemplete';
          }
          this.detailForm.show = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取末次随瓶安检详情
    async getBotDetailInfo() {
      try {
        let params = {
          // CustomerId:'sdsA464Sr+yurAruzUWP6Q==',
          CustomerId: this.propsData.Id,
          InspectType: 2,
        };
        let res = await getLastInspectionDetail(params);
        console.log(res);
        if (res?.Success) {
          if (!res.Data) return this.$message.warning('该用户无末次随瓶安检');
          this.detailForm.TemplateId = res.Data.TemplateId;
          this.detailForm.CusId = this.propsData.Id;
          this.detailForm.Type = this.upData.TypeName == '居民' ? 1 : 2;
          this.detailForm.form = res.Data;
          if (res.Data.TemplateId) {
            this.detailForm.templateName = 'BottleTempDynamic';
          } else {
          }
          this.detailForm.show = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.el-icon-remove-outline {
  color: red;
  font-size: 18px;
  margin-left: 10px;
}
.el-icon-circle-plus-outline {
  color: #0090fc;
  font-size: 18px;
  margin-left: 10px;
}
</style>
