<template>
  <div :class="{ 'see-info-box': comin != 1 }" v-loading="tableLoading">
    <p class="see-title" v-if="comin != 1">订单信息</p>
    <div class="gh-see-box">
      <table class="cus-table">
        <tbody>
          <tr>
            <th width="20%">订单号</th>
            <td width="30%">{{ detailsForm.Number }}</td>
            <th width="20%">客户证号</th>
            <td width="30%">{{ detailsForm.CusNumber }}</td>
          </tr>

          <tr>
            <th width="20%">下单人</th>
            <td width="30%">{{ detailsForm.OrderPeople }}</td>
            <th width="20%">下单时间</th>
            <td width="30%">
              <el-date-picker v-if="orderTimeCanEdit=='1'" v-model="detailsForm.OrderTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="" :clearable="false"></el-date-picker>
              <template v-else>{{ detailsForm.OrderTime ? $ghGetTodayTime.ghFilter(detailsForm.OrderTime) : '' }}</template>
            </td>
          </tr>
          <tr>
            <th width="20%">派单人</th>
            <td width="30%">{{ detailsForm.Assigner }}</td>
            <th width="20%">派单时间</th>
            <td width="30%">{{ detailsForm.AssignTime ? $ghGetTodayTime.ghFilter(detailsForm.AssignTime) : '' }}</td>
          </tr>
          <tr>
            <template v-if="type == 'edit'">
              <th width="20%">收货人</th>
              <td width="30%"><el-input v-model="detailsForm.Receptor" clearable></el-input></td>
              <th width="20%">收货电话</th>
              <td width="30%"><el-input v-model="detailsForm.ReceptPhone" clearable></el-input></td>
            </template>
            <template v-else>
              <th width="20%">收货人</th>
              <td width="30%">{{ detailsForm.Receptor }}</td>
              <th width="20%">收货电话</th>
              <td width="30%">{{ detailsForm.ReceptPhone }}</td>
            </template>
          </tr>
          <tr>
            <th width="20%">订单状态</th>
            <td width="30%">{{ detailsForm.StatusDesc }}</td>
            <th width="20%">受理时间</th>
            <td width="30%">{{ detailsForm.WxAcceptTime }}</td>
          </tr>
          <tr>
            <th width="20%">收货地址</th>
            <template v-if="type == 'edit'">
              <td colspan="3"><el-input v-model="detailsForm.ReceptAddress" clearable></el-input></td>
            </template>
            <template v-else>
              <td colspan="3">{{ detailsForm.ReceptAddress }}</td>
            </template>
          </tr>
          <tr>
            <th width="20%">订单类型</th>
            <td width="30%">{{ detailsForm.OrderTypeDesc }}</td>
            <th width="20%">支付方式</th>
            <td width="30%">{{ detailsForm.PayTypeDesc }}</td>
          </tr>
          <tr>
            <th width="20%">订单总额</th>
            <td width="30%">{{ detailsForm.Money }}</td>
            <th width="20%">实付金额</th>
            <td width="30%">{{ detailsForm.PayMoney }}</td>
          </tr>
          <tr>
            <th width="20%">楼层费用</th>
            <td width="30%">{{ detailsForm.FloorsMoney }}</td>
          </tr>
          <tr>
            <th width="20%">配送费</th>
            <td width="30%">{{ detailsForm.DeliverPrice }}</td>
            <th width="20%">优惠券抵扣</th>
            <td width="30%">{{ detailsForm.CouponMoney }}</td>
          </tr>
          <tr>
            <th width="20%">积分抵扣</th>
            <td width="30%">{{ detailsForm.PointMoney }}</td>
            <th width="20%">交易单号</th>
            <td width="30%">{{ detailsForm.TransactionId }}</td>
          </tr>
          <tr>
            <th width="20%">备注</th>
            <td v-if="type == 'edit'" colspan="3"><el-input v-model="detailsForm.Remark" clearable></el-input></td>
            <td v-else colspan="3">{{ detailsForm.Remark }}</td>
          </tr>
          <tr>
            <th width="20%">撤单人</th>
            <td width="30%">{{ detailsForm.Quasher }}</td>
            <th width="20%">撤单时间</th>
            <td width="30%">{{ detailsForm.QuashTime }}</td>
          </tr>
          <tr>
            <th width="20%">撤销原因</th>
            <td colspan="3">{{ detailsForm.QuashReason }}</td>
          </tr>
          <tr>
            <th width="20%">结单人</th>
            <td width="30%">{{ detailsForm.Settler }}</td>
            <th width="20%">结单时间</th>
            <td width="30%">{{ detailsForm.SettleTime }}</td>
          </tr>
          <tr>
            <th width="20%">结单备注</th>
            <td colspan="3">{{ detailsForm.SettleRemark }}</td>
          </tr>
          <tr>
            <th width="20%">拒单人</th>
            <td width="30%">{{ detailsForm.Refuser }}</td>
            <th width="20%">拒单时间</th>
            <td width="30%">{{ detailsForm.RefuseTime }}</td>
          </tr>
          <tr>
            <th width="20%">拒单理由</th>
            <td colspan="3">{{ detailsForm.RefuseReason }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <p class="see-title">配送信息</p>
    <div class="gh-see-box" style="border:none">
      <table class="cus-table">
        <tbody>
          <tr>
            <th width="20%">配送员</th>
            <td width="30%">{{ detailsForm.Operator }}</td>
            <th width="20%">配送时间</th>
            <td width="30%">{{ detailsForm.DeliveryTime ? $ghGetTodayTime.ghFilter(detailsForm.DeliveryTime) : '' }}</td>
          </tr>
          <tr>
            <th>预约送气时间</th>
            <td>
              {{ detailsForm.ReserveBeginTime ? $ghGetTodayTime.ghFilter(detailsForm.ReserveBeginTime) : '' }}
              -{{ detailsForm.ReserveEndTime ? $ghGetTodayTime.ghFilter(detailsForm.ReserveEndTime) : '' }}
            </td>
            <th>完成时间</th>
            <td>{{ detailsForm.FinishTime ? $ghGetTodayTime.ghFilter(detailsForm.FinishTime) : '' }}</td>
          </tr>
          <tr>
            <th>满瓶条码</th>
            <td colspan="3">{{ bara }}</td>
          </tr>
          <tr>
            <th>空瓶条码</th>
            <td colspan="3">{{ barb }}</td>
          </tr>
          <tr>
            <th width="20%">催单时间</th>
            <td width="30%">{{ detailsForm.RemindTime }}</td>
            <th width="20%">催单人员</th>
            <td width="30%">{{ detailsForm.Reminder }}</td>
          </tr>
          <template v-if="detailsForm.LastReassignInfo">
            <tr>
              <th width="20%">转派人员</th>
              <td width="30%">{{ detailsForm.LastReassignInfo.Reassigner }}</td>
              <th width="20%">转派时间</th>
              <td width="30%">{{ detailsForm.LastReassignInfo.ReassignTime }}</td>
            </tr>
            <tr>
              <th width="20%">转派原因</th>
              <td colspan="3">{{ detailsForm.LastReassignInfo.ReassignReason }}</td>
            </tr>
          </template>
          <tr v-if="orderDevlieryAddPicture=='1'">
            <th >照片</th>
            <td colspan="3">
              <div class="gh-img-box">
                <el-link type="primary" v-if="devlieryPicture.length>0">查看</el-link>
                <el-image class="gh-img-text" ref="getImg" :src="devlieryPicture[0]" :preview-src-list="devlieryPicture"> </el-image>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-if="detailsForm.IsReturnGas">
      <p class="see-title">退余气信息</p>
      <div class="gh-see-box" style="border:none">
        <table class="cus-table">
          <tbody>
            <tr>
              <th width="20%">退气气瓶规格</th>
              <th width="30%">气瓶数量</th>
              <th width="20%">净重（kg）</th>
              <th width="30%">退余气金额（元）</th>
            </tr>
            <tr v-for="item in tyqTable.DetailModel" :key="item.Id">
              <td>{{ item.CylinderSpec }}</td>
              <td>{{ item.CylinderCount }}</td>
              <td>{{ item.ReturnWeight }}</td>
              <td>{{ item.ReturnMoney }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <p class="see-title">商品信息</p>
    <div class="gh-see-box">
      <table class="cus-table">
        <tbody>
          <tr>
            <th width="30%">商品名称</th>
            <th width="25%">分类</th>
            <th width="15%">单价</th>
            <th width="15%" v-if="detailsForm.PurchType == 1">瓶数</th>
            <th width="15%">{{ detailsForm.PurchType == 1 ? '重量KG' : '数量' }}</th>
          </tr>
          <tr v-for="(item, index) in detailsForm.Detail" :key="index">
            <td>{{ item.ProductName }}</td>
            <td>{{ item.ClassifyName }}</td>
            <td>{{ item.Price }}</td>
            <td v-if="detailsForm.PurchType == 1">{{ item.CylinderCount }}</td>
            <td>{{ item.Quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="see-title">特价商品</p>
    <div class="gh-see-box">
      <table class="cus-table">
        <tbody>
          <tr>
            <th width="30%">商品名称</th>
            <th width="25%">分类</th>
            <th width="15%">单价</th>
            <th width="15%" v-if="detailsForm.PurchType == 1">瓶数</th>
            <th width="15%">{{ detailsForm.PurchType == 1 ? '重量KG' : '数量' }}</th>
          </tr>
          <tr v-for="(item, index) in detailsForm.Detail2" :key="index">
            <td>{{ item.ProductName }}</td>
            <td>{{ item.ClassifyName }}</td>
            <td>{{ item.Price }}</td>
            <td v-if="detailsForm.PurchType == 1">{{ item.CylinderCount }}</td>
            <td>{{ item.Quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-if="detailsForm.CylinderPledgeData && detailsForm.CylinderPledgeData.length > 0">
      <p class="see-title">押瓶信息</p>
      <span class="totalprice-span">
        押金总额:<span style="color:red">{{ detailsForm.TotalPrice }}</span
        >,租金总额: <span style="color:red">{{ detailsForm.RentPrice }}</span></span
      >
      <div class="gh-see-box">
        <table class="cus-table">
          <tbody>
            <tr>
              <th width="30%">钢瓶规格</th>
              <th width="30%">押瓶方式</th>
              <th width="20%">单价</th>
              <th width="20%">数量</th>
              <th width="20%">重量</th>
            </tr>
            <tr v-for="(item, index) in detailsForm.CylinderPledgeData" :key="index">
              <td>{{ item.CylinderSpec }}</td>
              <td>{{ item.PledgeTypeDesc }}</td>
              <td>{{ item.DepositPrice }}</td>
              <td>{{ item.CylinderCount }}</td>
              <td>{{ item.WeightPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="type != '3' && detailsForm.OrderCopy && detailsForm.OrderCopy.length > 0">
      <p class="see-title">变更记录</p>
      <div class="gh-see-box">
        <table class="cus-table">
          <tbody>
            <tr>
              <th width="30%">变更时间</th>
              <th width="30%">操作人员</th>
              <th width="20%">原因</th>
              <th width="20%">订单快照</th>
            </tr>
            <tr v-for="(item, index) in detailsForm.OrderCopy" :key="index">
              <td>{{ item.OperatorTime }}</td>
              <td>{{ item.Operator }}</td>
              <td>{{ item.Reason }}</td>
              <td>
                <el-link type="primary" @click="showHistor(item.Id)">查看</el-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <p class="see-title">修改原因</p>
    <div class="gh-see-box">
      <table class="cus-table">
        <tbody>
          <tr>
            <th width="20%">修改原因</th>
            <td v-if="type == 'edit'" colspan="3"><el-input v-model="detailsForm.Reason" clearable></el-input></td>
            <td v-else colspan="3">{{ detailsForm.Reason }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-btn" v-if="type == 'edit' && comin != 1">
      <el-button type="primary" round @click="chageOrder">保存</el-button>
      <el-button round @click="$emit('close')">取消</el-button>
    </div>
    <!-- 查看弹窗 -->
    <XkMaxDialog :dialogVisible.sync="showHistorBoolen" :title="`历史修改信息`" :width="'200'" :getIsfull="false">
      <template #content>
        <OrderManageInfo :id="historyId" type="3" @close="showHistorBoolen = false"></OrderManageInfo>
      </template>
    </XkMaxDialog>
  </div>
</template>
<script>
import XkMaxDialog from '@/components/XkMaxDialog';
import { detailOrder, UpdateOrder, GetOrderCopy } from '@/api/order';
import { getResByOrderNumber } from '@/api/recovery';
import { getConfigList, getConfigAllList } from '@/api/sysConfig';
export default {
  name: 'OrderManageInfo',
  data() {
    return {
      tyqTable: {},
      detailsForm: {},
      bara: '',
      barb: '',
      csArry: [
        { fullOrEmpty: 1, barcode: '测试1' },
        { fullOrEmpty: 2, barcode: '测试2' },
      ],
      ruls: {
        Receptor: '请输入收货人',
        ReceptPhone: '请输入收货电话',
        ReceptAddress: '请输入收货地址',
        Reason: '请输入修改原因',
      },
      showHistorBoolen: false,
      historyId: '',
      orderTimeCanEdit:'0',
      devlieryPicture:[],
      orderDevlieryAddPicture:'0'
    };
  },
  components: {
    XkMaxDialog,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    comin: {
      //判断入口
      type: Number,
      default: '',
    },
  },
  created() {
    this.getSysConfig()
    this.getDetails();
  },
  methods: {
    async getOrderReturnGas() {
      try {
        let res = await getResByOrderNumber({ orderNumber: this.detailsForm.Number });
        if (res?.Success) {
          this.tyqTable = res.Data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 查看历史变更
    showHistor(Id) {
      this.showHistorBoolen = true;
      this.historyId = Id;
    },
    async chageOrder() {
      try {
        let params = {
          Id: this.detailsForm.Id,
          Receptor: this.detailsForm.Receptor,
          ReceptPhone: this.detailsForm.ReceptPhone,
          ReceptAddress: this.detailsForm.ReceptAddress,
          Reason: this.detailsForm.Reason,
          Remark: this.detailsForm.Remark,
          OrderTime: this.detailsForm.OrderTime,
        };
        for (let i in params) {
          if (!params[i] && this.ruls[i]) {
            this.$message.error(this.ruls[i]);
            return;
          }
        }
        let res = await UpdateOrder(params);
        if (res?.Success) {
          this.$message({
            type: 'success',
            message: '提交成功',
          });
          this.$emit('close');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDetails() {
      this.tableLoading = true;
      try {
        let res;
        if (this.type != '3') {
          res = await detailOrder({ id: this.id });
        } else {
          res = await GetOrderCopy({ id: this.id });
        }
        if (res.Success) {
          this.detailsForm = res.Data;
          // 当前订单存在退余气则请求数据
          if (this.detailsForm.IsReturnGas) {
            this.getOrderReturnGas();
          }
          if(this.detailsForm.DevlieryPicture){
            this.devlieryPicture = this.detailsForm.DevlieryPicture.split(',')
          }
          this.bara = [];
          this.barb = [];
          this.detailsForm.Deliver.forEach((element) => {
            if (element.FullOrEmpty) {
              this.bara.push(element.Barcode);
            }
            if (!element.FullOrEmpty) {
              this.barb.push(element.Barcode);
            }
          });
          this.bara = this.bara.join('/');
          this.barb = this.barb.join('/');
          console.log('this.detailsForm', res.Data);
          this.tableLoading = false;
        }
      } catch (error) {
        this.tableLoading = false;
      }
    },
    // 获取配置
    async getSysConfig() {
      let res = await getConfigAllList();
      let sysConfigFrom = JSON.parse(JSON.stringify(res.Data));
      if (res.Success) {
        sysConfigFrom.forEach((item) => {
          // 下单时间可修改
          if (item.Key == 'OrderTimeCanEdit') {
            this.orderTimeCanEdit = item.Value
          }
          // 销售单配送时显示照片
          if (item.Key == 'OrderDevlieryAddPicture') {
            this.orderDevlieryAddPicture = item.Value
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.see-info-box {
  overflow: auto;
}
.totalprice-span {
  margin-left: 10%;
  margin-bottom: 10px;
}
.gh-img-box {
  position: relative;
  height: 30px;
}
.gh-img-text {
  position: absolute;
  width: 100%;
  height: 30px;
  left: 0;
  top: -20px;
  opacity: 0;
}
</style>
