/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-16 14:34:25
 * @LastEditors: lushunjie
 * @LastEditTime: 2024-11-07 14:55:41
 * @FilePath: \src\api\safetyCheck.js
 */
// 安检管理
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Inspection/`

//  列表
export const getSafetyCheckList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config })
}
 //  详情
// export const getSafetyCheckDetail = (params, config = {}) => {
//     return request.get(apiPrefix + 'SingleDetail', { params: params, ...config })
// }
// 居民详情
export const getResidentDetail = (params, config = {}) => {
    return request.get(apiPrefix + 'ResidentDetail', { params: params, ...config })
}
// 非居民详情
export const getNonResidentDetail = (params, config = {}) => {
    return request.get(apiPrefix + 'NonResidentDetail', { params: params, ...config })
}
// 详情非居民 居民 二合一
export const getCombineDetailAsync = (params, config = {}) => {
    return request.get(apiPrefix + 'GetCombineDetailAsync', { params: params, ...config })
}
//  安检图片
export const getSafetyCheckImages = (params, config = {}) => {
    return request.get(apiPrefix + 'Images', { params: params, ...config })
}
//  燃气用户末次安检详情
export const getCustomerLastInspectDetail = (params, config = {}) => {
    return request.get(apiPrefix + 'CustomerLastInspectDetail', { params: params, ...config })
}
//  燃气用户末次安检详情(定期+随瓶)
export const getLastInspectionDetail = (params, config = {}) => {
    return request.get(apiPrefix + 'LastInspectionDetail', { params: params, ...config })
}
//  随瓶安检记录
export const ListWithBottle = (params, config = {}) => {
    return request.get(apiPrefix + 'ListWithBottle', { params: params, ...config })
}
//  随瓶安检明细
export const DetailWithBottle = (params, config = {}) => {
    return request.get(apiPrefix + 'DetailWithBottle', { params: params, ...config })
}
// 随瓶安检结论
export const ValidityWithBottleEnums = (params, config = {}) => {
    return request.get(apiPrefix + 'ValidityWithBottleEnums', {params:params, ...config})
}
const apiPrefixA = `${process.env.VUE_APP_BASE_API}/DropDownList/`
// 平铺 站点
export const FillingStationList = (params, config = {}) => {
    return request.get(apiPrefixA + 'FillingStationList', {params:params, ...config})
}
// 平铺 门店
export const SupplyStationList = (params, config = {}) => {
    return request.get(apiPrefixA + 'SupplyStationList', {params:params, ...config})
}